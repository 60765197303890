import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getRecaptchaCredentials, isRestrictedEmailDomain, postSubmitAccount, resetError, resetIsRestrictedEmailDomain} from '../actions/accountsetupAction';
import BarLoader from 'react-spinners/BarLoader';
import '../style/css/homeComponent.css'

const HomeComponent = () => {
    const  {isRestrictedEmailDomains, loadingStarts, isEmailAlreadyExists, submitSuccess} = useSelector((state) => state.accountsetupReducer)

    const dispatch = useDispatch();
    const [state, setState] = useState({})
    const [isRestricted,setIsRestricted]=useState("");
    const [isRestrictedFlag,setIsRestrictedFlag]=useState("");

    useEffect(() => {
        dispatch(getRecaptchaCredentials())
    }, [dispatch])

    useEffect(() => {
        if(isRestrictedEmailDomains === "YES"){
            setIsRestricted(isRestrictedEmailDomains)
            setIsRestrictedFlag(isRestrictedEmailDomains)
        }
        if(isRestrictedEmailDomains === "NO"){
            setIsRestricted(isRestrictedEmailDomains)
            setIsRestrictedFlag(isRestrictedEmailDomains)
        }
    }, [isRestrictedEmailDomains])

    const setButtonClass = () => {
        if(!state.email || !state.reEnterEmail || state.incorrectRenterEmailAdd || state.errorEmailDontMatch || state.incorrectEmailAdd || submitSuccess || isEmailAlreadyExists === 'True'|| isRestrictedFlag === "YES") {
            return 'primary disabled'
        }

        return 'primary'
    }

    const onChange = (e) => {
        let temp = { ...state, [e.target.name]: e.target.value }
        if(e.target.name === 'email' || e.target.name === 'reEnterEmail'){
            temp = {...temp, ...validateEmail(e)}
        }
        if(e.target.name === 'email' && e.target.value.length === 0){
            setIsRestricted("NO");
            dispatch(resetIsRestrictedEmailDomain());
        }
        dispatch(resetError())
        setState({...temp});
    }

    /*****************************************************************
     * Triggered : On blur of email address text box
     * Description : This function is used to validate email address.
     ******************************************************************/
	const validateEmail = (event) => {	
        // eslint-disable-next-line
        let letters = /^\w+([\.]?[-]?[\.-]\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        let temp = {
            incorrectEmailAdd: false,
            errorEmailInternalUsers: false,
            errorEmailDontMatch: false,
            incorrectRenterEmailAdd: false,
            errorReEmailInternalUsers: false
        }
        if (event.target.name === "email") {
            if (event.target.value.match(letters)) {
                if(event.target.value.endsWith("@juniper.net", event.target.value.length) ||event.target.value.endsWith("@jnpr.net", event.target.value.length)){
                    temp.errorEmailInternalUsers = true;
                }
            } else {
                temp.incorrectEmailAdd = true;
            }

            if (state.reEnterEmail && state.reEnterEmail !== event.target.value) {
                    temp.errorEmailDontMatch = true ;
            } 
        }

        if (event.target.name === "reEnterEmail") {
            if (event.target.value.match(letters)) {
                if(event.target.value.endsWith("@juniper.net", event.target.value.length) || event.target.value.endsWith("@jnpr.net", event.target.value.length)){
                    temp.errorReEmailInternalUsers = true;
                }
            } else {
                temp.incorrectRenterEmailAdd = true;
            }

            if (state.email && state.email !== event.target.value) {
                temp.errorEmailDontMatch = true ;
            } 
        }

        return temp;
        
    }


    const checkRestrictedEmailDomain = (emailDomain) => {
        var url = "/isRestrictedEmailDomain?emailDomain="+ emailDomain
        dispatch(isRestrictedEmailDomain(url))
    }

    const validateEmailExist = (event) => {
        // eslint-disable-next-line
        let letters = /^\w+([\.]?[-]?[\.-]\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;

        if(event.target.value.match(letters) && (!event.target.value.endsWith("@juniper.net", event.target.value.length) && !event.target.value.endsWith("@jnpr.net", event.target.value.length))){
            checkRestrictedEmailDomain("@"+event.target.value.split("@")[1].replace(/ /g,''))
        }
    }

    const handleSubmit = () => {
        let data = { emailAddress: state.email }
        dispatch(postSubmitAccount(data))
    }

    const showSuccessMessage = () => {
        if(submitSuccess) {
            return true
        }

        return false
    }
    
    return <div>
        <div className="grid padding-around font-size-medium center-element width-80">
            <div className="col-12">
                <p className="font-size-xlarge text-uppercase"><strong>User Registration</strong></p>
				<p className="font-size-large margin-top-large margin-bottom"><strong>Create a New user Account </strong></p>
				<p className="margin-top-large margin-bottom">Welcome! You can register for a Juniper user account if you are a:</p>
                <ul>
                    <li>Customer who is authorized to access exclusive information and resources in the Juniper Support Portal (JSP). </li>
                    <li>Guest who would like to access the Elevate community, virtual labs such as vLabs and JCL, educational resources in the Learning Portal, or browse product information, and who voluntarily provides certain information.</li>
                </ul>
                <p><strong>NOTE:</strong> Partners have exclusive log-in credentials. To request a new partner account, visit <a href="https://juniper.my.site.com/prm/s/help" target="_blank" rel="noopener noreferrer">Partner Assistance</a></p>

                <div className="padding-top">
                <p>Juniper will process your information in accordance with our <a target="_blank" rel="noopener noreferrer" href="https://www.juniper.net/us/en/privacy-policy.html">Privacy Notice.</a></p>
                    <div className="grid">
                        
                        <div className="col-6">
                            
                            <p className="text-input-label margin-bottom">Email Address <span className="text-required">*</span> <span className="padding-top-smaller text-hint">(this will be your Juniper Account ID)</span></p>

                            <div className="field-format">
                                <input 
                                    type="text" 
                                    placeholder="Type here..." 
                                    name="email" 
                                    onChange={onChange}
                                    onBlur={validateEmailExist}
                                    />

                                    <BarLoader  width={"50%"} color={'#84B135'} loading={loadingStarts} />

                                    {isRestricted === "YES" && state.email.length !==0 && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Email Domain Restricted!</p>}

                                    { state.erroremailAdd && state.email.length !==0 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Please provide Email Address!</p>}
                                    
                                    { state.incorrectEmailAdd && state.email.length !==0 && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Invalid Email Address!</p>}
                                    
                                    { state.errorEmailInternalUsers && state.email.length !==0 && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Internal Users are Restricted!</p>}
                            </div>
                        </div>

                        <div className="col-6">
                            <p className="text-input-label margin-bottom">Re-enter Email Address <span className="text-required">*</span></p>

                            <div className="field-format">
                                <input type="text" placeholder="Type here..." name="reEnterEmail" onChange={onChange}/>
                                { state.errorrenterEmailAdd && state.reEnterEmail.length !==0 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Please Re-enter Email Address!</p>}

                                { !state.incorrectRenterEmailAdd && state.errorEmailDontMatch && state.reEnterEmail.length !==0 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Email Address and Re-entered Email Address must match.</p> } 

                                { state.incorrectRenterEmailAdd && state.reEnterEmail.length !==0 &&  <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Invalid Email Address!</p>}

                            </div>

                        </div>
                    </div>

                    <div className="padding-top right-align">
                        <button className={setButtonClass()} disabled={isRestrictedEmailDomains === "YES"} onClick={handleSubmit}>Submit</button>
                    </div>

                    {showSuccessMessage() &&<div className="grid margin-top-16">
                        <div className="padding-top alert alert-success">
                          <p> Thank you for registering. Please check your email for instructions on completing the registration process.<br></br>
                            If you do not receive an email within 24 hours, please contact our <a href="https://support.juniper.net/support/requesting-support/" target="_blank" rel="noopener noreferrer">Customer Care Team.</a>
                            </p>
                        </div>
                    </div>
                    }
                   
                </div>
            </div>
        </div>
    </div>
}

export default HomeComponent