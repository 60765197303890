import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { acceptEncryption, clearSuccessMsg, createUser, getLoggedInUserInfo, resetAcceptEncryptValue, submitMarketingPreferences } from '../actions/encryptionAgreementAction';
import '../style/css/encryptionAgreement.css';
import { CLOUD_USER } from '../utils/constants';
import DisclaimerDomainDialog from "./DisclaimerDialogComponent";
import FooterHyperlinks from "./FooterHyperlinksComponent";
let applicationData = {};
/*******************************************************
 * Screen - Encryption Agreement
 * Description - This is the third step needed for User 
 *               Registration.
 ******************************************************/
class EncryptionAgreementComponent extends React.Component {
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        // this.createNewUser = this.createNewUser.bind(this);
        this.cancelCheckBox = this.cancelCheckBox.bind(this);
        this.acceptEncryptionAgreement = this.acceptEncryptionAgreement.bind(this);
        this.state = {
            enableNextButton : false,
            checked : false,
            encryptionRequest : '',
            userRegFlow : true
        }
        this.eloQuaRef = React.createRef();
    }
    componentDidMount = () => {
        window.scrollTo(0,0);

        if (this.props.location.pathname.indexOf('acceptencryptionagreement') < 0) {
            this.setState({userRegFlow : true})
        }else{
            this.setState({userRegFlow : false})
            this.props.getLoggedInUserInfo('/getLoggedInUserInfo');
        }
    }
      
    //Logic return to navigate to account submission page on successful creation of user
    componentDidUpdate(prevProps){
		if(prevProps.userCreated !== this.props.userCreated){
			if(this.props.userCreated === 'Success'){
                this.props.clearSuccessMsg();
                const payload = {firstName: applicationData.accountCreationObj.firstName, lastName: applicationData.accountCreationObj.lastName, emailAddress: applicationData.accountCreationObj.emailAddress, country: applicationData.accountCreationObj.country, state_Prov: applicationData.accountCreationObj.stateName, marketingPrefSelected: applicationData.accountCreationObj.eloQua &&applicationData.accountCreationObj.eloQua.length? applicationData.accountCreationObj.eloQua.join(",") : ""}
                this.props.submitMarketingPreferences(payload)
                .then(res => {
                    this.props.history.push({
                        pathname : "/accountSubmission",
                        state : {userIdCreated : applicationData.accountCreationObj.emailAddress,
                            serialNumRes : applicationData.accountSetUpObj.serialNumRes, 
                            errorMarketingPreference: this.props.errorMarketingPreference, isPublicUser: applicationData.accountSetUpObj.isPublicUser, userType: applicationData.accountSetUpObj.isGuestUserReq? "Base User": applicationData.accountSetUpObj.userType, companyName: applicationData.accountCreationObj.companyName  }
                    })  
                })
                .catch(err => console.log(err));
			}
		}
    }
    
    //This function is triggered on change of check box
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.checked});
        if(event.target.checked){
            this.setState({checked : true});
        }else{
            this.setState({checked : false});
        }
    }

    //This function is triggered on click of cancel button of User Registration flow
    cancelAgreement = () =>{
        this.props.history.push("/");
    }

    //Function is triggered on click of cancel button of standalone edit encryption page, uncheck the checkbox and disable
    //Accept button
    cancelCheckBox(){
        this.setState({
            checked : false,
            encryptionRequest : false
        })
    }
    /**********************************************************
     * Triggered : On click of Back button
     * Description : This function is used to navigate back to 
     *               Account Creation page.
     * 
     *********************************************************/
    navigateToAccountCreation() {
        this.props.history.push({
            pathname : "/accountinformation",
            state: { applicationData : applicationData },
        })
    }
    /**********************************************************
     * Triggered : On click of Next button
     * Description : This function is used to navigate  to 
     *               Account Submission page.
     * 
     *********************************************************/

    navigateToAccountSubmissionBaseUser(){
        let parsedAccountCreationData;
        let parsedAcctSetUpObj;
        let parsedCloudObj;
        if(undefined !== this.props.location.state){
            applicationData = this.props.location.state.applicationData
        }
            parsedAccountCreationData = applicationData.accountCreationObj;
            parsedAcctSetUpObj = applicationData.accountSetUpObj;
            parsedCloudObj = applicationData.cloudProviderObj;

        let createUserPayURL = "/createUser";
        let updatedCompanyName = "";
        
        if(parsedAccountCreationData.personalUse){
            updatedCompanyName = "PERSONAL USE "+ parsedAccountCreationData.firstName.toUpperCase() + " " + parsedAccountCreationData.lastName.toUpperCase();
        }
        else{
            updatedCompanyName = parsedAccountCreationData.companyName;
        }
        let updatedUserRole = "";
        // if(parsedAcctSetUpObj.isLabUser.toString() === "true"){
        //     updatedUserRole = "Lab User"
        // }else if(parsedAcctSetUpObj.isLabUser.toString() === "false"){
        //     updatedUserRole = "Base User"
        // }else{
            updatedUserRole = parsedAcctSetUpObj.loggedInUser
        // }
        let createUserPayload = {
                "emailAddress" : parsedAccountCreationData.emailAddress,
                "userType" : updatedUserRole,
                "companyId" : parsedAcctSetUpObj.companyId,
                "firstName" : parsedAccountCreationData.firstName,
                "lastName" : parsedAccountCreationData.lastName,
                "companyName" : updatedCompanyName,
                "companyUrl" : parsedAccountCreationData.compUrl,
                "addressLine1" : parsedAccountCreationData.add1,
                "addressLine2" : parsedAccountCreationData.add2,
                "city" : parsedAccountCreationData.city,
                "state" : parsedAccountCreationData.stateProvince,
                "zip" : parsedAccountCreationData.zip,
                "countryCode" : parsedAcctSetUpObj.countryCode,
                "countryName" : parsedAcctSetUpObj.country,
                "phoneNumber" : '+' + parsedAccountCreationData.phoneCode + ' ' + parsedAccountCreationData.phoneNum + ' Ext# '+ parsedAccountCreationData.phoneExt,
                "faxNumber" : parsedAccountCreationData.fax,
                "jobTitle" : parsedAccountCreationData.jobTitle,
                "serialNumber" : parsedAcctSetUpObj.serialNumber,
                "source" : updatedUserRole === CLOUD_USER ? "CLOUD" : "UserReg",
                "createdDate" : new Date().toISOString(),
                "jobFunction" : parsedAccountCreationData.primaryRole,
                "encryptionRequest" : this.state.checked ? "YES": "NO",
                "authorizationCode" : parsedAcctSetUpObj.authCode,
                "encryptionRequestDate" : new Date().toISOString(),
                "timeZone" : parsedAccountCreationData.timeZone,
                "cloudProviderId" : updatedUserRole === CLOUD_USER ? parsedCloudObj.cloudProvider : "",
                "cloudAccountId" : updatedUserRole === CLOUD_USER ? parsedCloudObj.cloudAccId : "",
                "cloudInstanceId" : updatedUserRole === CLOUD_USER && null != parsedCloudObj.cloudInstanceId ? parsedCloudObj.cloudInstanceId : "",
                "cloudProductCode" : updatedUserRole === CLOUD_USER ? parsedCloudObj.cloudProductTitle : "",
                "cloudRegionId" : updatedUserRole === CLOUD_USER ? parsedCloudObj.cloudRegion : "",
                "supplierAppName" :parsedAccountCreationData.suppApps,
                "isGroupAccount" : parsedAcctSetUpObj.isIndividualEmail? "NO" : "YES",
                "oSId" : parsedAcctSetUpObj.serialNumRes && parsedAcctSetUpObj.serialNumRes.OSId,
				"oSDisplayName" : parsedAcctSetUpObj.serialNumRes && parsedAcctSetUpObj.serialNumRes.OSDisplayName,
				"modelShortName" : parsedAcctSetUpObj.serialNumRes && parsedAcctSetUpObj.serialNumRes.modelShortName,
                "sapAcctId" : parsedAcctSetUpObj.sapAccountId,
                "elevatedCircleCode": parsedAcctSetUpObj.elevatedCircleCode,
                "publicDomainFlag": parsedAcctSetUpObj.isPublicUser === "True"? "YES" : "NO",
                "sapAccountId": parsedAcctSetUpObj.sapAccountId,
                 "token": parsedAcctSetUpObj.captchaToken,
                 "jplaAcceptance":parsedAccountCreationData.isActivationAllowed === true ? "YES": "NO",
                 "jplaAcceptedDate":parsedAccountCreationData.isActivationAllowed === true ? new Date().toISOString() : "",
                 "jplaVersion":parsedAccountCreationData.isActivationAllowed === true ? parsedAccountCreationData.jplaAgreement.jplaVersion : "",
                 "jplaFilename":parsedAccountCreationData.isActivationAllowed === true ? parsedAccountCreationData.jplaAgreement.filePath : "",
                 "jplaSource":"User Registration",
        }        
        // This is the change request for okta sprint 5
        if(parsedAcctSetUpObj.isGuestUserReq) {
            createUserPayload.isGuestUserReq = true;
            createUserPayload.userType = null;
        }

		if(parsedAcctSetUpObj.isCloudUserReq) {
            createUserPayload.isCloudUserReq = true;
            createUserPayload.userType = null
        }

        if(parsedAcctSetUpObj.isLabUserReq) {
            createUserPayload.isLabUserReq = true;
            createUserPayload.userType = null
        }

        if(parsedAcctSetUpObj.isEvaluationUserReq) {
            createUserPayload.isEvaluationUserReq = true;
            createUserPayload.userType = null
        }

        if(parsedAcctSetUpObj.isEndUserReq) {
            createUserPayload.userType = "End User";
            delete parsedAcctSetUpObj.isEndUserReq;
        }
        
        if(updatedUserRole !== ""){
            this.createNewUser(createUserPayURL,createUserPayload, parsedAcctSetUpObj.eloQua);
        }
    }

    createNewUser(createUserPayURL,createUserPayload){
        this.props.createUser(createUserPayURL,createUserPayload);
    }
    

    //This function is used to invoke accept encryption agreement page API
    acceptEncryptionAgreement(){
        var requestPayload = {
            "encryptionRequested" : this.state.checked ? 'YES' : "NO",
            "incomingSource" : applicationData && applicationData.accountSetUpObj && applicationData.accountSetUpObj.loggedInUser === CLOUD_USER ? "CLOUD" : "UserReg",
            }
        this.props.loggedInUserInfo && this.props.acceptEncryption(`/updateEncryptionAgreement/${this.props.loggedInUserInfo.userId}`, requestPayload)
    }

    closeDialog(){
        this.props.resetAcceptEncryptValue();
    }

    render() {
        let parsedAccountCreationData, phoneNum;
        let {loadingStarts,loggedInUserInfo, acceptEncrypt} = this.props;
        if(undefined !== this.props.location.state){
            applicationData = this.props.location.state.applicationData
        }
            parsedAccountCreationData = applicationData.accountCreationObj;
        let PopulatedState = loggedInUserInfo !== null  && loggedInUserInfo.state !== null ? loggedInUserInfo.state : "N/A";
        let parsedState = parsedAccountCreationData !== undefined && parsedAccountCreationData.stateProvince !== "" ? parsedAccountCreationData.stateProvince : "N/A";
        let address = parsedAccountCreationData !== undefined ?  parsedAccountCreationData.city+", "+ parsedState+", "+ parsedAccountCreationData.zip : 
        loggedInUserInfo !== null && loggedInUserInfo.city + ", " + PopulatedState + ", "+ loggedInUserInfo.zip
        if(loggedInUserInfo && loggedInUserInfo.phoneNumber && loggedInUserInfo.phoneNumber.indexOf('Ext#') > 0){
            phoneNum = loggedInUserInfo.phoneNumber.split('Ext#')[0]
        }else if(loggedInUserInfo !== null){
            phoneNum = loggedInUserInfo.phoneNumber
        }

        return (
            <div>
                <LoadingOverlay
                    active={loadingStarts}
                    spinner
                    text='Registering User...'
                >
	<div style = {{ overflowY : 'auto', height : '600px'}} className="grid padding-around font-size-medium center-element width-80">
    
   
            <div className="col-12">
                <div className="flex margin-bottom-small header-container">
                    <div className="font-size-xlarge text-uppercase account-title">
                    {this.state.userRegFlow ? <strong>User Registration</strong> : <strong>Encryption Agreement</strong>}
                    </div>
                    { !this.state.userRegFlow  && 
                    <div className="font-size-header">{loggedInUserInfo !== null && loggedInUserInfo.userId} |  
                    <span className="hyperlink padding-left-smaller">
                       <a href="/saml/logout"> Logout </a>
                    </span>
                    </div>}
                </div>
            {this.state.userRegFlow  && <br/>}
			<p className="to-download-encrypte {">TO DOWNLOAD ENCRYPTED SOFTWARE           
				<br /> This agreement is required by Federal law to download encrypted software. Once you certify and agree, click "Submit" to complete your registration.
			</p>
			<hr className="line-5"></hr>
			<div className="padding-top">
				<div className="grid">
					<p className="the-agreement-you-ha">
                                    THE AGREEMENT
                        
						<br /> You have accessed this webpage either after applying for a new account 
                        on behalf of a Juniper Networks customer or partner ("Company") or through an account already 
                        opened by Juniper Networks to such a Company. The account (the "Account") has (or will have) a 
                        specific Juniper Networks enables the account for download of Juniper Networks Encryption Items, 
                        you must complete and submit the Certification and Undertaking Regarding Export of Juniper Networks 
                        Encryption Items ("Certification and Undertaking").
                    
					</p>
				</div>
				<div className="grid">
					<p className="the-agreement-you-ha">
                        ANYONE WHO USES THE ACCOUNT TO ACCESS JUNIPER NETWORKS ENCRYPTION SOFTWARE ADOPTS AND AGREES TO THE TERMS OF
                         THE Certification and Undertaking.
                    </p>
				</div>
                <div className="grid">
					<p className="the-agreement-you-ha">
                            Certification and Undertaking Regarding Export of Juniper Networks Encryption Items 
                            ("CERTIFICATION AND UNDERTAKING")
                    </p>
				</div>
                <div className="grid flex-vertical margin-left-largest">
                    <div className="user-det-container">
                        <div className="user-det-key">Name</div>
                        <div className="user-det-val">{parsedAccountCreationData !== undefined  ? parsedAccountCreationData.firstName +" "+ parsedAccountCreationData.lastName : 
                        loggedInUserInfo !== null && loggedInUserInfo.firstName + " " + loggedInUserInfo.lastName}</div>
                    </div>
                    <div className="user-det-container">
                        <div className="user-det-key">Email Address</div>
                        <div className="user-det-val">{parsedAccountCreationData !== undefined ?parsedAccountCreationData.emailAddress : loggedInUserInfo !== null && loggedInUserInfo.userId}</div>
                    </div>
                    {(parsedAccountCreationData !== undefined && parsedAccountCreationData.companyName && parsedAccountCreationData.companyName.trim() !== "") && <div className="user-det-container">
                        <div className="user-det-key">Company Name</div>
                        <div className="user-det-val">{ parsedAccountCreationData !== undefined ?parsedAccountCreationData.companyName : loggedInUserInfo !== null && loggedInUserInfo.companyName}</div>
                    </div>}
                    <div className="user-det-container">
                        <div className="user-det-key">Phone Number</div>
                        <div className="user-det-val">{parsedAccountCreationData !== undefined 
                         ? '+' + parsedAccountCreationData.phoneCode + ' ' + parsedAccountCreationData.phoneNum
                        : phoneNum}</div>
                    </div>
                    <div className="user-det-container">
                        <div className="user-det-key">Address</div>
                        <div className="user-det-val">{parsedAccountCreationData !== undefined ?
                         parsedAccountCreationData.add1+ " "+parsedAccountCreationData.add2 : loggedInUserInfo !== null && loggedInUserInfo.address1 + " "+loggedInUserInfo.address2}</div>
                    </div>
                    <div className="user-det-container">
                        <div className="user-det-key">City, State, Zip</div>
                        <div className="user-det-val">{address}</div>
                    </div>
				</div>
                <div className="grid">
                    <p className="the-agreement-you-ha">
                    On my own behalf and on behalf of Company, I hereby certify, represent and agree as follows:
                    </p>
                </div>
                <div className="grid">
                    <p className="the-agreement-you-ha padding-top">
                    All information set forth in this Certification and Undertaking (including, without limitation, the information set forth above regarding Company and me) is current, complete and accurate. I shall immediately notify Seller at the Notice Address indicated below of any change in or modification to the information certified to hereby.
                    I am not a resident or citizen of (and my place of employment is not located in) any of the following countries: Iran, Libya, Cuba, Syria, North Korea, Sudan. Neither I nor the Company shall not export or re-export nor install or use any software or license key accessed from this account to or in any such country.
                    I will not furnish any such software or license key to any citizen or resident of any such country.<br></br>

                    I understand that Juniper Networks Encryption Items are subject to U.S and foreign import/export controls, and the Company and I each agree not to export or re-export any such item except in compliance with all applicable import/export laws and regulations both U.S. and foreign. Without limiting the foregoing, the Company may not export or re-export (whether by download, shipment or disclosure to a foreign national of another country) any Juniper Networks Encryption Item unless it obtains an export license or complies with all conditions, limitations and reporting and other requirements of the so-called "ENC" license exception (Sec. 740.17 of the US Export Administration Regulations).
                    I understand that each time the Account is used to access and download software to a location outside the United States, I am engaged in an export of that software, and that anytime I transport a copy of such downloaded software to another country or furnishes it to a foreign national of another country, I am engaged in a re-export of that software and therefore must comply with applicable US and foreign export control laws and regulations.
                    Neither I nor the Company is engaged in any nuclear, missile, chemical or biological warfare proliferation activities (not applicable to US Government end users, to which other rules apply).<br></br>

                    Neither I nor the Company is a "government end-user" (as defined in Part 772 of the US Export Administration Regulations) of any country other than Canada, Austria, Australia, Belgium, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Japan, Latvia, Lithuania, Luxembourg, 
                    Malta, Netherlands, New Zealand, Norway, Poland, Portugal, Slovakia, Slovenia, Spain, Sweden, Switzerland and the United Kingdom.<br></br>

                    Without prior approval from the U.S. Department of Commerce, Bureau of Industry and Security ("BIS"), neither I nor the Company shall engage in any export or 
                    re-export of any Juniper Networks Encryption Items classified as "5A002 restricted" or "5D002 restricted" to any 
                    "government end-user" (as defined in Part 772 of the US Export Administration Regulations) of any country other than 
                    Canada, Austria, Australia, Belgium, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary,
                     Ireland, Italy, Japan, Latvia, Lithuania, Luxembourg, Malta, Netherlands, New Zealand, Norway, Poland, Portugal, Slovakia, 
                     Slovenia, Spain, Sweden, Switzerland and the United Kingdom. (Examples of "5D002 restricted" products include: JUNOS (domestic) software (including JUNOS (domestic) for J-Series); 
                     JUNOSe software; SDX application software. Examples of "5A002 restricted" products include: all E-series Products; M-, T- or J-Series products on which JUNOS (Domestic) software is loaded; 
                     and NS50, 204, 208, 500, 5200 or 5400; ISG2000. Contact Export_Compliance_Desk@juniper.net if you need further details on specific products or components.)<br></br>
                    
                    Neither I nor Company shall engage in any export or re-export of Juniper Networks products (whether or not Encryption Items) in violation of any US or Canadian embargo (including, without limitation, 
                    any such embargo administered by US Office of Foreign Asset Control (OFAC) or Canadian DFAIT)).<br></br>
                    Neither I nor Company shall engage in any export or re-export of any Juniper Networks Encryption Item to any entity/person prohibited by U.S, Canadian or other applicable
                     law or regulation from receiving exports (including, without limitation, those listed on the Denied Parties List, the Entity List and 
                     the Specially Designated Nationals List);<br></br>
                    Juniper Networks may log and disclose to the U.S. and/or Canadian governments the contents of this Certification and Undertaking and the facts and circumstances of any download or other export or re-export of Juniper Networks Encryption Items. The information provided will be released and processed only to satisfy lawful requests from government agencies or to ensure compliance with applicable government laws and regulations.
                    Juniper Networks may request as a condition of any shipment or download of Juniper Networks Encryption Items 
                    that Company execute and deliver one or more additional certifications or other documentation where Juniper Networks deems it necessary or appropriate in its discretion.<br></br>
                    Juniper Networks Notice Address:<br></br>
                    C/o Juniper Networks, Inc. <br></br>
                    Attn: Legal Department (Export Compliance) <br></br>
                    1194 N. Mathilda Ave.<br></br> 
                    Sunnyvale, CA 94089<br></br> 
                    USA
                    </p>
                    </div>
                <div className="grid">
                    <p className="the-agreement-you-ha">
                    The foregoing certifications and undertaking shall apply with respect to all downloads of Juniper Networks Encryption Items from this site through use of this Account. Each time this Account is used to download any Juniper Networks Encryption Item, the Company and I shall each be thereby reaffirming the currency and accuracy of the facts certified to herein as though made as of the date of such access.
                    </p>
                </div>   
                <div className="grid col-12">
                <div className="grid flex-horizontal">
								<label className="container ">
										<input type="checkbox" name="encryptionRequest" value={this.state.encryptionRequest} onChange={this.handleChange} checked={this.state.encryptionRequest}/>
										<span className="checkmark"></span>
									</label><div style={{bottom : 3}} >I SO CERTIFY AND AGREE.</div>
								</div>
			    </div>
                {this.state.userRegFlow  ? <div className="grid nav-buttons">
                    <div>
                        <button onClick={() => this.navigateToAccountCreation()} className=" primary rectangle-back-button"><span className="back">&lt; Back </span></button>
                    </div>&nbsp; &nbsp;
                    <div>
                        <button onClick={() => this.navigateToAccountSubmissionBaseUser()} className= {this.state.checked ? "primary rectangle-back-button" : "primary disabled rectangle-back-button"}><span className="back">Submit</span></button> 
                    </div>
                    <div className="padding-around-small">
                       <div className="cancel hyperlink-cus" onClick={this.cancelAgreement.bind(this)}> Cancel</div>
                    </div>
                     </div>  : <div className="grid nav-buttons">
                     
                    <div>
                        <button onClick={() => this.acceptEncryptionAgreement()} className= {this.state.checked ? "primary rectangle-back-button" : "primary disabled rectangle-back-button"}><span className="back">Accept</span></button>
                    </div>
                    <div className="padding-around-small">
                       <div className="cancel hyperlink-cus" onClick = {() => this.cancelCheckBox()}> Cancel</div>
                    </div>

                     </div>}
                     
                     {this.state.userRegFlow  &&  <FooterHyperlinks history={this.props.history}/> }
                </div>
		</div>
       
	</div>
    </LoadingOverlay>
    <DisclaimerDomainDialog dialogName = "confirmation-dialog" accountTitle={"ACCEPT ENCRYPTION AGREEMENT"} accountText={'You have accepted Encryption Agreement.'}
                openDialog = {acceptEncrypt === 'Success' ? true : false} closeDialog = { this.closeDialog.bind(this)} history = {this.props.history}/>
</div>

        )
    }

}

/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state){
	return {
        userCreated: state.encryptionAgreementReducer.userCreated,
        loadingStarts : state.encryptionAgreementReducer.loadingStarts,
        loggedInUserInfo : state.encryptionAgreementReducer.loggedInUserInfo,
        acceptEncrypt : state.encryptionAgreementReducer.acceptEncrypt,
        errorMarketingPreference: state.encryptionAgreementReducer.errorMarketingPreference
	}
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
    createUser,
    clearSuccessMsg,
    getLoggedInUserInfo,
    acceptEncryption,
    resetAcceptEncryptValue,
    submitMarketingPreferences
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps) (EncryptionAgreementComponent)